import React, { useState } from 'react';
import { PolicyList } from '@components/Policies/PolicyList';
import * as contentful from 'types/contentful';
import { MainMenu } from '@components/MainMenu';
import SortBar from '@components/SortBar';
import isEmpty from 'lodash/isEmpty';
import { PolicyCardsSearch } from '@components/Policies/PolicyCardsSearch';
import { NoCardMatchesFoundMessage } from '@components/NoCardMatchesFoundMessage';
import { SuppliersCaveat } from '@components/SuppliersCaveat';
import { InlineContainer } from '@components/InlineContainer';
import { Item } from '@components/SortBar/types';
import { IPageProps } from '../types';

const sortByItem: Item[] = [
  {
    label: 'Alphabetically',
    fieldName: 'titleRailCarrier',
  },
  {
    label: 'Last updated',
    fieldName: 'updatedAt',
  },
];

const PolicyRailCarriersPage = (
  props: IPageProps<contentful.RailCarrier[]>,
): React.ReactElement => {
  const {
    uri,
    navigate,
    pageContext,
    location: { pathname },
  } = props;
  const [filterData, setFilterData] = useState<contentful.RailCarrier[]>([]);
  const [emptyItemsMessageVisible, setEmptyItemsMessageVisibile] = useState(false);
  const [sortBySelectedOption, setSortBySelectedOption] = useState(sortByItem[0]);

  const displayFilterData = (data: contentful.RailCarrier[]): void => {
    setEmptyItemsMessageVisibile(false);
    setFilterData(data);
  };

  const handleSortSelect = (data: contentful.RailCarrier[], selectedOption: Item): void => {
    setSortBySelectedOption(selectedOption);
    setFilterData(data);
  };

  const handleSearchChange = (data: contentful.RailCarrier[], isResetData: boolean): void => {
    if (isResetData) {
      displayFilterData(pageContext.data);

      return;
    }

    if (isEmpty(data)) {
      setEmptyItemsMessageVisibile(true);
    } else {
      displayFilterData(data);
    }
  };

  const data = !isEmpty(filterData) ? filterData : pageContext.data;

  return (
    <>
      <MainMenu uri={uri} navigate={navigate} staticUri={pathname} />
      <InlineContainer>
        <PolicyCardsSearch
          handleValueChange={handleSearchChange}
          data={pageContext.data}
          searchFields={['titleRailCarrier', 'carrierCode']}
          filterByFieldName="titleRailCarrier"
        />
        <SortBar
          data={data}
          options={sortByItem}
          handleSortSelect={handleSortSelect}
          selectedOption={sortBySelectedOption}
        />
        <SuppliersCaveat />
      </InlineContainer>
      {emptyItemsMessageVisible ? (
        <NoCardMatchesFoundMessage />
      ) : (
        <PolicyList
          getItemProps={(item: contentful.RailCarrier) => {
            return {
              items: [
                {
                  content: item.faceMasks?.json,
                  title: 'Face Masks',
                  classImg: 'faceMasksImg',
                },
                {
                  content: item.healthChecksDocuments?.json,
                  title: 'Health Checks',
                  classImg: 'healthChecksImg',
                },
                {
                  content: item.socialDistancing?.json,
                  title: 'Social Distancing',
                  classImg: 'socialDistancingImg',
                },
                {
                  content: item.cleaningPolicy?.json,
                  title: 'Cleaning Policy',
                  classImg: 'cleaningPolicyImg',
                },
                {
                  content: item.onboard?.json,
                  title: 'Onboard',
                  classImg: 'onboardImg',
                },
                {
                  content: item.fb?.json,
                  title: 'F&B',
                  classImg: 'foodImg',
                },
              ],
              title: item.titleRailCarrier,
              updatedAt: item.updatedAt,
              logoUrl: item.logoImage?.file.url,
              link: item.railCarrierUrl
                ? { url: item.railCarrierUrl.link, label: item.railCarrierUrl.label }
                : undefined,
              key: item.id,
            };
          }}
          data={data}
        />
      )}
    </>
  );
};

export default PolicyRailCarriersPage;
